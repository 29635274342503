import React from 'react'
import './about.css'
import ME from '../../assets/anya3.png'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'


const About = () => {
  return (
    <section id='about'>
      <h5>Get to Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About" />
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon"/>
              <h5>Personas</h5>
              <small>3+ Roles Learning</small>
            </article>

            <article className="about__card">
              <FiUsers className="about__icon"/>
              <h5>Conversation</h5>
              <small>200k+ Worldwide</small>
            </article>

            <article className="about__card">
              <VscFolderLibrary className="about__icon"/>
              <h5>Tasks</h5>
              <small>80k+ Completed</small>
            </article>
          </div>

          <p>
            ANYA is an AI system designed to optimise the yield of manufacturing processes by leveraging advanced neural networkalgorithms to predict and prevent quality issues in real-time. 
          </p>
          <a href="#contact" className='btn btn-primary'>Let's Talk</a>
        </div>
      </div>
    </section>
  )
}

export default About