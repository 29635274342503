import React from "react";
import CV from '../../assets/cv.pdf'

const CTA = () => {
  return (
    <div className="cta">
      
    </div>
  );
};

export default CTA;
