import React from 'react'
import './services.css'
import {BiCheck} from 'react-icons/bi'

const Services = () => {
  return (
    <section id='services'>
      <h5>Anya As An Employee</h5>
      <h2>Responsibilities</h2>

      <div className='container services__container'>
        

          
            
        <article className='service'>
          <div className='service__head'>
            <h3>Receptionlist</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Greetings.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Scheduling Appointments.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Managing phonecalls.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Maintaining Records.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Assisting with administrative tasks.</p>
            </li>
            
            
          </ul>
        </article>
        {/* END OF WEB DEVELOPMENT */}
        <article className='service'>
          <div className='service__head'>
            <h3>HR</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Recruitment.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Onboarding.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Performance Management.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Data Analytics.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Benefits Administration.</p>
            </li>
            
            
          </ul>
        </article>
        {/* END OF WEB DEVELOPMENT */}<article className='service'>
          <div className='service__head'>
            <h3>SCRUM Master</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Facilitating Scrum Meetings.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Coaching the Scrum team.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Removing impediments.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Managing Scrum Process.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Communicating with Scrum Holders.</p>
            </li>
            
            
          </ul>
        </article>
        {/* END OF WEB DEVELOPMENT */}

        
      </div>
      
    </section>
  )
}

export default Services