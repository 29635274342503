import React from 'react'
import './testimonials.css'
import AVTR1 from '../../assets/avartar1.jpg'
import AVTR2 from '../../assets/avartar2.jpg'
import AVTR3 from '../../assets/avartar3.jpg'
import AVTR4 from '../../assets/avartar4.jpg'


// import Swiper core and required modules
import { Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';



const data = [
  {
    avatar: AVTR1,
    name: 'Tina Snow',
    review: 'Using the chatbot product has been a game-changer for our business. Not only has it saved us time and resources by automating routine customer inquiries, but it has also improved our customer experience by providing fast and accurate responses 24/7.'
  },
  {
    avatar: AVTR2,
    name: 'Shatta Wale',
    review: 'Integrating a chatbot into our business has been a game-changer for our customer service. Not only has it reduced the burden on our human support team, but it has also improved our response times and overall customer satisfaction. The chatbot  ability to handle a large volume of inquiries simultaneously has allowed us to serve our customers faster and better.'
  },
  {
    avatar: AVTR3,
    name: 'Kwame Despite',
    review: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec dui id mi dignissim posuere feugiat vitae eros. Fusce vitae neque sit amet enim iaculis placerat eget et est. Integer.'
  },
  {
    avatar: AVTR4,
    name: 'William Simon',
    review: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec dui id mi dignissim posuere feugiat vitae eros. Fusce vitae neque sit amet enim iaculis placerat eget et est. Integer.'
  }
]


const Testimonials = () => {
  return (
    <section id='testimonials'>
      <h5>Review from clients</h5>
      <h2>Testimonials</h2>

      <Swiper className='container testimonials__container'
      // install Swiper modules
      modules={[Pagination]}
      spaceBetween={40}
      slidesPerView={1}
      pagination={{ clickable: true }}
      >

        {
          data.map(({avatar, name, review}, index) => {
            return (
              <SwiperSlide key={index} className='testimonial'>
                <div className='client__avatar'>
                  <img src={avatar} alt="avatar" />            
                </div>
                <h5 className='client__name'>{name}</h5>
                <small className='client__review'>
                  {review}
                </small>
              </SwiperSlide>
            )
          })
        }        

      </Swiper>
    </section>
  )
}

export default Testimonials